<template>
  <div class="bid-logs">
    <div class="search-filter-form">
      <div class="d-flex flex-column flex-lg-row gacell-padding gap-.5">
        <div class="d-flex gap-.5">
          <div class="select">
            <b-form-select :value="selectedProject" class="inner_select" @change="selectProject($event)">
              <b-form-select-option v-for="option in projectBodies" :key="option.Seq" :value="option.Seq" :label="option.ProjectNM"></b-form-select-option>
            </b-form-select>
          </div>
          <div class="select">
            <b-form-select :value="selectedKeyword" class="inner_select" @change="selectKeyword($event)">
              <b-form-select-option v-for="keyword in keywords" :key="keyword.Seq" :value="keyword.nccKeywordID" :label="`${keyword.MGubun} ${keyword.Keyword}`"></b-form-select-option>
            </b-form-select>
          </div>
        </div>
        <div class="d-flex gap-.5">
          <div class="filter-area p-0 w-full">
            <div class="date">
              <button class="inner_btn" variant="primary" :class="{ 'opacity-.3': disableDateButton === 'prev' }" :disable="disableDateButton === 'prev'" size="sm" @click="addDate('-')"><i class="bi bi-chevron left"></i></button>
              <b-form-datepicker :value="datePickerValue" class="inner_picker text-center" :min="minDate" :max="today" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" @input="selectDate" />
              <button class="inner_btn" variant="primary" :class="{ 'opacity-.3': disableDateButton === 'next' }" :disable="disableDateButton === 'next'" size="sm" @click="addDate('+')"><i class="bi bi-chevron right"></i></button>
            </div>
          </div>
          <div class="filter-area p-0 flex-shirnk-0">
            <div class="select">
              <button class="inner_btn" variant="primary" size="sm" :class="{ 'opacity-.3': disableTimeButton === 'prev' }" :disable="disableTimeButton === 'prev'" @click="addTime('-')"><i class="bi bi-chevron left"></i></button>
              <b-form-select :value="selectedTime" class="time-select inner_select text-center" @change="selectTime">
                <b-form-select-option v-for="index in 24" :key="index" :value="index - 1">{{ addLeadingZero(index - 1) }} : 00</b-form-select-option>
              </b-form-select>
              <button class="inner_btn" variant="primary" size="sm" :class="{ 'opacity-.3': disableTimeButton === 'next' }" :disable="disableTimeButton === 'next'" @click="addTime('+')"><i class="bi bi-chevron right"></i></button>
            </div>
          </div>
        </div>

        <!-- 여백위해 넣은 div / 모바일에서는 없어도 OK start -->
        <div class="flex-grow-1 d-none d-lg-block"></div>
        <!-- 여백위해 넣은 div / 모바일에서는 없어도 OK end -->
      </div>
    </div>
    <!-- 간단한 테이블 -->
    <div class="card mt-1 mb-0">
      <b-table :fields="tableHeads" :items="tableRows" responsive :class="{ nodata: displayClass === 'nodata', load_ing: displayClass === 'loading' }" class="adjust-table text-center">
        <template v-slot:cell(0)="{ value }"> {{ value['L목표순위'] }}</template>
        <template v-slot:cell(1)="{ value }">
          <span :class="{'text-red': isDifferentColor(value)}">{{ value['현재순위'] }}</span>
        </template>
        <template v-slot:cell(2)="{ value }"> {{ value['현재입찰액'] }}</template>
        <template v-slot:cell(3)="{ value }"> {{ value['증가액'] }}</template>
        <template v-slot:cell(4)="{ value }"> {{ value['변경입찰액'] }}</template>
        <template v-slot:cell(5)="{ value }"> {{ value['최상제한액'] }}</template>
        <template v-slot:cell(6)="{ value }"> {{ value['CrawlDatetime'].replace(/\.\d*/g, '') }}</template>
      </b-table>
    </div>
  </div>
</template>
<script>
import { getStrDate } from '@/utils/DateUtils'
import { addLeadingZero, formatCurrency, formatDateString } from '@/utils/StringUtils'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  components: {},
  mixins: [],
  data() {
    return {
      selectedProject: undefined,
      keywords: undefined,
      selectedKeyword: undefined,
      selectedDate: getStrDate(new Date()),
      selectedTime: new Date().getHours(),
      list: undefined,
      today: new Date(),
      disableDateButton: undefined,
      disableTimeButton: undefined
    }
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapGetters('filterParam', ['params']),
    ...mapGetters('bid', ['projectBodies']),
    ...mapState('bid', ['logKeywords', 'selectedLogKeyword']),

    displayClass() {
      if (this.loading && !this.list) {
        return 'loading'
      } else if (!this.loading && this.tableRows && this.tableRows.length === 0) {
        return 'nodata'
      } else {
        return ''
      }
    },
    minDate() {
      const BASE_DATE = 7
      const sevenDaysAgo = new Date()
      sevenDaysAgo.setDate(new Date().getDate() - BASE_DATE)
      return sevenDaysAgo
    },
    datePickerValue() {
      return new Date(formatDateString(this.selectedDate))
    },
    tableHeads() {
      return [
        { key: '0', label: '목표', class: 'cell-padding' },
        { key: '1', label: '현재', class: 'cell-padding' },
        { key: '2', label: '현재₩', class: 'cell-padding' },
        { key: '3', label: '+/-(원)', class: 'cell-padding' },
        { key: '4', label: '변경₩', class: 'cell-padding' },
        { key: '5', label: '제한₩', class: 'cell-padding' },
        { key: '6', label: '수집기간', class: 'cell-padding' }
      ]
    },
    tableRows() {
      // 현재입찰액 증가액 최상제한액 변경입찰액
      return (this.list?.Body || []).map(item => {
        const obj = {
          0: {
            L목표순위: item['L목표순위'],
            class: 'cell-padding'
          },
          1: {
            현재순위: item['현재순위'],
            L목표순위: item['L목표순위'],
            class: 'cell-padding'
          },
          2: {
            현재입찰액: item['현재입찰액'],
            class: 'cell-padding'
          },
          3: {
            증가액: item['증가액'],
            class: 'cell-padding'
          },
          4: {
            변경입찰액: item['변경입찰액'],
            class: 'cell-padding'
          },
          5: {
            최상제한액: item['최상제한액'],
            class: 'cell-padding'
          },
          6: {
            CrawlDatetime: item['CrawlDatetime'],
            class: 'cell-padding'
          }
        }
        return obj
      })
    }
  },
  methods: {
    ...mapActions('bid', ['fetchProjects', 'fetchLogKeywords', 'fetchLogList']),
    ...mapMutations('bid', ['SELECT_LOG_KEYWORD']),
    ...mapMutations('common', ['SET_LOADING']),

    formatCurrency,
    addLeadingZero,

    selectProject(value) {
      if (this.selectedProject === value) return
      this.selectedProject = value

      this.SET_LOADING(true)
      return this.fetchLogKeywords(this.selectedProject)
        .then(() => (this.keywords = this.logKeywords[value].Body))
        .then(() => this.selectKeyword(this.keywords?.[0]?.nccKeywordID))
    },
    selectKeyword(value) {
      if (this.selectedKeyword === value) return
      this.selectedKeyword = value

      this.search()
    },
    selectDate(value) {
      if (this.selectedDate !== value) this.selectedDate = value

      this.search()
    },
    addDate(type) {
      let current = new Date(formatDateString(this.selectedDate))

      if (type === '-') {
        current.setDate(current.getDate() - 1)
        if (this.today.getDate() - current.getDate() <= 7) this.selectDate(current.toISOString().split('T')[0])
      } else {
        current.setDate(current.getDate() + 1)
        if (this.today.getDate() - current.getDate() >= 0) this.selectDate(current.toISOString().split('T')[0])
      }
    },
    selectTime(value) {
      this.selectedTime = value
      this.search()
    },
    addTime(type) {
      if (type === '-') {
        let time = this.selectedTime - 1
        if (time < 0) return
        this.selectTime(time)
      } else {
        let time = this.selectedTime + 1
        if (time > 23) return
        this.selectTime(time)
      }
    },
    search() {
      if (!this.selectedKeyword) return
      this.list = undefined

      const time = String(this.selectedTime).length === 1 ? 0 + String(this.selectedTime) : String(this.selectedTime)

      this.SET_LOADING(true)
      const params = { keyword_id: this.selectedKeyword, begin_time: `${this.selectedDate} ${time}:00:00`, end_time: `${this.selectedDate} ${time}:59:59` }
      return this.fetchLogList(params)
        .then(res => (this.list = res.data))
        .then(() => this.SET_LOADING(false))
    },
    selectSelectedLogKeyword() {
      return this.selectProject(this.selectedLogKeyword.project).then(() => {
        this.selectKeyword(this.selectedLogKeyword.nccKeywordidId)
        this.SELECT_LOG_KEYWORD()
      })
    },
    dateFormatter(value) {
      // value는 선택된 날짜의 JavaScript Date 객체입니다.
      if (value) {
        const year = value.getFullYear()
        const month = (value.getMonth() + 1).toString().padStart(2, '0')
        const day = value.getDate().toString().padStart(2, '0')
        return `${year}년 ${month}월 ${day}일`
      }
      return null
    },
    isDifferentColor(value) {
      return String(value['L목표순위']) !== String(value['현재순위'])
    }
  },
  created() {
    this.fetchProjects().then(() => (this.selectedLogKeyword ? this.selectSelectedLogKeyword() : this.selectProject(this.projectBodies[0]?.Seq)))
  },
  watch: {
    selectedDate: {
      handler(date) {
        const next = new Date(formatDateString(date))
        next.setDate(next.getDate() - 1)

        const prev = new Date(formatDateString(date))
        prev.setDate(prev.getDate() - 1)

        this.disableDateButton = this.today.getDate() - next.getDate() <= 1 ? 'next' : this.today.getDate() - prev.getDate() > 7 ? 'prev' : undefined
      },
      immediate: true
    },
    selectedTime: {
      handler(time) {
        const next = time + 1
        const prev = time - 1

        this.disableTimeButton = next > 23 ? 'next' : prev < 0 ? 'prev' : undefined
      },
      immediate: true
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/scss/search-form.scss';

.bid-logs {
  .select {
    select::-ms-expand {
      display: none;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: white;
    }
  }

  @media (max-width: 304px) {
    .b-form-datepicker {
      font-size: 12px;
  
      .text-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
  
        padding: 0;
      }
  
      > .btn {
        width: 0;  
      }
    }

    .select {
      .time-select {
        font-size: 12px;
      }
    }
  }
}
</style>
